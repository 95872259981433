import React from 'react';
import './App.scss';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-175803086-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();

history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

console.log(' ___________________\n' +
    ' | _______________ |\n' +
    ' | |XXXXXXXXXXXXX| |\n' +
    ' | |XXXXXXXXXXXXX| |\n' +
    ' | |XXXXXXXXXXXXX| |\n' +
    ' | |XXXXXXXXXXXXX| |\n' +
    ' | |XXXXXXXXXXXXX| |\n' +
    ' |_________________|\n' +
    '     _[_______]_\n' +
    ' ___[___________]___\n' +
    '|         [_____] []|__\n' +
    '|         [_____] []|  \\__\n' +
    'L___________________J     \\ \\___\\/\n' +
    ' ___________________      /\\\n' +
    '/###################\\    (__)');

console.log('Are you looking around?');

function App() {
  return (
    <div className="App">
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    </div>
  );
}

export default App;
