import React from 'react';

function NotFound() {
    return (
        <header className="App-header">
            <h1>Woops, that page does not exist.</h1>
        </header>
    );
}

export default NotFound;

