import React from 'react';

function Home() {
    return (
        <header className="App-header">
            <div className="box">
                <div className="title">
                    <span className="block"></span>
                    <h1>Daan Peters<span></span></h1>
                </div>

                <div className="role">
                    <div className="block"></div>
                    <p>Full-stack web developer</p>
                </div>

                <div className="links">
                    <a href="https://github.com/peters97" target="_blank" rel="noopener noreferrer">Github</a>
                    <a href="https://www.linkedin.com/in/daan-ptrs/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                </div>
            </div>
        </header>
    );
}

export default Home;

